<template>
  <div
    class="modal fade"
    :id="`modalSetFunctionSelectedModel`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalSetFunctionSelectedModelLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header p-2">
          <h5 class="modal-title" :id="`modalSetFunctionSelectedModelLabel`">
            <i class="bi bi-filetype-json me-1"></i>
            <template v-if="this.selectedModelType == 1">
              {{
                $t(
                  "SetModel.EditOutputModelForFunction",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
            <template v-else>
              {{
                $t(
                  "SetModel.EditInputModelForFunction",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
          <SetModelEdit
            ref="setModelEdit"
            :selectedModelType="selectedModelType"
          />
        </div>
        <div
          class="modal-footer align-items-center p-4"
          :class="{
            'justify-content-between': !isLocalObject,
            'justify-content-end': isLocalObject,
          }"
        >
          <div
            class="alert alert-info float-start p-1 mb-1 mt-0 me-1 ms-1"
            v-if="!isLocalObject"
          >
            {{
              $t(
                "SetCodeFunctions.RecordIsAvailableOnTheSystemMessage",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
            <a
              :href="`#/SetCode/Model/Edit/${model.publicId}`"
              target="_blank"
              class="btn btn-warning btn-sm ms-2"
              ><i class="bi bi-pencil-square ms-1 me-1"></i>
              {{
                $t(
                  "SetCodeFunctions.RedirectUpdatePage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </a>
          </div>
          <div>
            <button
              @click="updateModel('selected-model-save')"
              type="button"
              class="btn btn-warning me-2 selected-model-save"
            >
              <span>
                <i class="bi bi-pencil-square"></i>
                {{
                  $t(
                    "Buttons.Edit",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
            </button>
            <button
              type="button"
              class="btn btn-danger btn-modal-selected-model-close"
              data-bs-dismiss="modal"
            >
              <i class="bi bi-x"></i>
              {{
                $t(
                  "Components.JsonViewerModal.Close",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
import SetModelEdit from "@/components/custom/setcode/model/SetModelEdit.vue";
export default {
  name: "ModelSelectedModal",
  components: {
    SetModelEdit,
  },
  props: {
    selectedModelType: {
      type: Number,
    },
    selectedModel: {
      type: Object,
    },
    refresh: {
      type: String,
    },
  },
  watch: {
    refresh() {
      $(".form-modal-edit-model").removeClass("was-validated");
      this.$refs.setModelEdit.setModelData = this.selectedModel;
      this.model = this.selectedModel;
      this.isLocalObject = String.isNullOrWhiteSpace(
        this.selectedModel.publicId
      );
    },
  },
  emits: ["onModelSelected"],
  data() {
    return {
      model: {},
      errors: [],
      isLocalObject: false,
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".selected-model-save",
    };
  },
  methods: {
    updateModel(buttonName) {
      var form = $(".form-modal-edit-model");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      const setModelEditComponent = this.$refs.setModelEdit;
      var setModel = { ...setModelEditComponent.setModelData };
      if (this.isLocalObject) {
        $(".btn-modal-selected-model-close").trigger("click");
        this.$emit("onModelSelected", setModel, this.selectedModelType);
      } else {
        var button = $(String.format(".{0}", buttonName)),
          firstSpan = button.find("span:first"),
          loadingBlock = button.find(".loading-block"),
          disabledButtons = $(this.disabledButtonClasses);

        disabledButtons.prop("disabled", true);
        firstSpan.hide();
        if (loadingBlock.length === 0) {
          firstSpan.after(this.submitButtonHtml);
          loadingBlock = button.find(".loading-block");
        } else {
          loadingBlock.show();
        }

        this.errors = [];
        this.$prodGatewayAxios
          .post("/Brs-SetCodeModelUpdate", setModel)
          .then((r) => {
            var response = r.data;
            if (response.isOk) {
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);

              createToast(
                this.$t(
                  "SetCodeFunctions.ModelUpdateMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                ).replaceAll("MODEL_NAME", setModel.name),
                {
                  showIcon: "true",
                  position: "top-right",
                  type: "success",
                  transition: "zoom",
                }
              );
              $(".btn-modal-selected-model-close").trigger("click");
              this.$emit("onModelSelected", setModel, this.selectedModelType);
            } else {
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              this.errors.push(response.msg);
            }
          })
          .catch((r) => {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(r);
          });
      }
    },
  },
};
</script>
