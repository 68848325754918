<template>
  <div
    class="modal fade"
    :id="`modalSetFunctionChangeModel`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalSetFunctionChangeModelLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title" :id="`modalSetFunctionChangeModelLabel`">
            <i class="bi bi-filetype-json me-1"></i>
            <template v-if="this.changedModelType == 1">
              {{
                $t(
                  "SetModel.PickOutputModelForFunction",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
            <template v-else>
              {{
                $t(
                  "SetModel.PickInputModelForFunction",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
          </h5>
          <ToggleButton
            classes="ms-3"
            :selected="display.grid"
            :first="
              $t(
                'SetModel.PickFromCurrentModels',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
            :second="
              $t(
                'SetModel.CreateNewModel',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
            @off="pickFormCurrentModels"
            @on="createNewModel"
          />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <Grid
            v-if="display.grid"
            ref="modelGrid"
            :settings="gridSettings"
            :columns="gridColumns"
            @onEditButtonClick="onEditButtonClick"
            @onModelButtonClick="onModelButtonClick"
          />
          <SetModelNew v-if="display.newRecord" ref="setModelNew" />
        </div>
        <div class="modal-footer p-4">
          <button
            v-if="display.newRecord"
            @click="saveModel"
            type="button"
            class="btn btn-success me-2"
          >
            <span>
              <i class="bi bi-save"></i>
              {{
                $t("Buttons.Save", {}, { locale: this.$store.state.activeLang })
              }}</span
            >
          </button>
          <button
            type="button"
            class="btn btn-danger btn-model-change-modal-close"
            data-bs-dismiss="modal"
          >
            <i class="bi bi-x"></i>
            {{
              $t(
                "Components.JsonViewerModal.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import SetModelNew from "@/components/custom/setcode/model/SetModelNew.vue";
export default {
  name: "ModelChangeModal",
  components: {
    SetModelNew,
  },
  props: {
    changedModelType: {
      type: Number,
    },
    model: {
      type: Object,
    },
    refresh: {
      type: String,
    },
  },
  watch: {
    refresh() {
      $(".form-model-change-modal-new-model").removeClass("was-validated");
      this.display = { grid: true, newRecord: false };
      // if (this.display.newRecord) {
      // }

      // if (this.display.grid) {
      //   this.$refs.modelGrid.grid.refresh();
      // }
    },
  },
  emits: ["onModelChanged", "onSelectedModel"],
  data() {
    return {
      display: { grid: true, newRecord: false },
      isSaveButtonHide: true,
      gridSettings: {
        action: "SetCodeModelList",
        requestUrl: String.format("/Brs-SetCodeModelList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "modelId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/SetCodeFunction/Edit/`,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: false,
        deleteActionUrl: "/Lcdp-CustomPageDelete",
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [
          {
            name: "select",
            cssClass: "btn-success",
            iconClass: "bi-check",
            routeButton: false,
            emitMethodName: "onModelButtonClick",
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "SetCodeModels.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "SetCodeModels.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "text",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "SetCodeModels.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    pickFormCurrentModels() {
      this.display = { grid: false, newRecord: true };
    },
    createNewModel() {
      this.display = { grid: true, newRecord: false };
    },
    onEditButtonClick(rowData) {
      let route = this.$router.resolve({
        name: "SetCodeModelEdit",
        params: {
          modelId: rowData.publicId,
        },
      });
      window.open(route.href);
    },
    saveModel() {
      var form = $(".form-model-change-modal-new-model");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var setModel = this.$refs.setModelNew.setModelData;
      this.$emit("onModelChanged", setModel, this.changedModelType);
      this.$emit("onSelectedModel", setModel, this.changedModelType);

      $(".btn-model-change-modal-close").trigger("click");
    },
    onModelButtonClick(rowData) {
      this.$emit("onSelectedModel", rowData, this.changedModelType);
      $(".btn-model-change-modal-close").trigger("click");
    },
  },
};
</script>
